.wd-course-card {
  width: 300px;
  margin-right: 40px;
  margin-bottom: 40px;
}

.wd-course-card-image {
  background-color: red;
  height: 150px;
}
