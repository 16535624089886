.wd-button {
  color: black !important;
  background-color: lightgray !important;
  border-color: gray !important;
  border-width: 0.5px !important;
}

.wd-button:hover {
  color: black !important;
  background-color: gray !important;
  border-color: gray !important;
  border-width: 0.5px !important;
}

.wd-button-red {
  color: white !important;
  background-color: red !important;
  border-color: red !important;
}

.wd-link {
  text-decoration: none;
  color: red;
}

.wd-button-green {
  color: white !important;
  background-color: green !important;
  border-color: green !important;
}

.wd-button-green:hover {
  color: white !important;
  background-color: darkgreen !important;
  border-color: darkgreen !important;
}
