.wd-course-navigation-item {
  text-decoration: none;
  color: red;
}

.wd-course-navigation-item-selected {
  color: black;
  border-left-color: black;
  border-left-style: solid;
  border-left-width: 2px;
}
